import React, { useEffect, useState } from "react";
import ATeamSide from "./ATeamSide";
import ATeamSaha from "./ATeamSaha";

const ATeam = () => {
  const [ates, setAtes] = useState(false);
  const [bom, setBom] = useState(false);


  useEffect(() => {
    setAtes(true);
  }, []);

  return (
    <div className="flex h-[600px]  overflow-x-hidden  overflow-y-scroll md:overflow-hidden   py-10 md:py-1 flex-col md:flex-row gap-4 md:gap-8 justify-start md:justify-center items-center bg-white bg-opacity-50 rounded-md">    
      <ATeamSaha bom={bom} />
   
      <ATeamSide ates={ates} />
    </div>
  );
};

export default ATeam;
