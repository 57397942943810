import React from "react";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

import "./App.css";
import Login from "./pages/Login";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FANSAPP_API_KEY,
  authDomain: process.env.REACT_APP_FANSAPP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FANSAPP_DATABASE_URL,
  projectId: process.env.REACT_APP_FANSAPP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FANSAPP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FANSAPP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FANSAPP_APP_ID,
  measurementId: process.env.REACT_APP_FANSAPP_MEASUREMENT_ID,
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const App: React.FC = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export { App, database }; 
