import React, { useEffect, useState } from "react";
import { ref, get, set } from "firebase/database";
import { database } from "../../App";


interface Card {
  id: number;
  name: string;
  select: boolean;
  yuzde: number;
}

const BTeamSide: React.FC<{ ates: boolean }> = ({ ates }) => {
  const initialVisible =
    localStorage.getItem("visible") === "false" ? false : true;
  const [visible, setVisible] = useState<boolean>(initialVisible);

  const [buttonVisible, setButtonVisible] = useState(true);
  const [cards, setCards] = useState<Card[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseRef = ref(database, "teams/bTeam/bTeamSidePlayer");
        const snapshot = await get(databaseRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const fansArray = Object.values(data) as Card[];
          setCards(fansArray);
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [ates]);

  const selectedCardCount = cards.filter((card) => card.select).length;
  const isButtonEnabled = selectedCardCount === 4;

  const clickOnCard = (cardId: number) => {
    if (visible) {
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.id === cardId
            ? {
                ...card,
                select: !card.select,
              }
            : card
        )
      );
    }
  };

  const done = async () => {
    const updatedCards = cards.map((card) => {
      const newYuzde = card.select ? card.yuzde + 0.5 : card.yuzde - 0.5;
      const newYuzdeInRange = Math.min(99, Math.max(10, newYuzde));
      const roundedYuzde = Math.round(newYuzdeInRange);


      return {
        ...card,
        yuzde: roundedYuzde,
      };
    });
    setCards(updatedCards);

    // Update the Firebase database with the updated cards
    const databaseRef = ref(database, "teams/bTeam/bTeamSidePlayer");
    try {
      await set(databaseRef, updatedCards);
    } catch (error) {
      console.error("Error updating the database:", error);
    }

    // Reset the 'select' property to 'false' for all cards in the database
    try {
      const resetDatabaseRef = ref(database, "teams/bTeam/bTeamSidePlayer");
      await set(
        resetDatabaseRef,
        updatedCards.map((card) => ({ ...card, select: false }))
      );
    } catch (error) {
      console.error(
        "Error resetting the 'select' property in the database:",
        error
      );
    }
    setVisible(false);
    // Save the updated visibility state to local storage
    localStorage.setItem("visible", (!visible).toString());

    setButtonVisible(true);
  };

  return (
    <div className="flex flex-col p-2 md:p-8 justify-center text-center items-center">
      <div className="flex  flex-col justify-center text-center items-center ">
          <h5 className="font-bold text-lg text-indigo-800 p-1">
          Must Play +
          </h5>
      </div>

      <div className=" border border-gray-800 flex flex-col items-center justify-start h-full md:h-[500px]  px-6 py-4 text-center overflow-hidden md:overflow-y-scroll">
        {cards.map((card) => (
          <div
            key={card.id}
            className={`border border-black rounded-md flex justify-center items-center text-center w-[250px] p-4 m-2 md:m-4 hover:cursor-pointer ${
              card.select && visible
                ? "bg-purple-600 bg-opacity-80"
                : "bg-indigo-900 bg-opacity-90"
            }`}
            onClick={() => clickOnCard(card.id)}
          >
            <div
              className={
                visible
                  ? "visible p-2 flex justify-center items-center"
                  : "hidden"
              }
            >
              <h1 className="border-blue-200 border-b-2 p-2 text-white text-sm ">
                {card.name}
              </h1>
            </div>
            <div
              className={
                visible
                  ? " hidden"
                  : "visible p-2 flex justify-around items-center"
              }
            >
              <h1 className="border-blue-200 border-b p-2 text-white text-sm w-[160px] overflow-hidden">
                {card.name}
              </h1>
              <h1 className="border-blue-200 border p-1 rounded-md bg-white bg-opacity-90 text-black text-center relative  text-bold">
                %{card.yuzde}
                
              </h1>
            </div>
          </div>
        ))}
           <div className={visible ? "" : "hidden"}>
        <button
          className={`border-2 border-black bg-blue-600 text-sm md:text-lg text-white font-bold rounded-md flex justify-center items-center text-center w-[180px] md:w-[250px] p-4 m-2 md:m-4 ${
            isButtonEnabled ? "" : "cursor-not-allowed opacity-60"
          }`}
          onClick={done}
          disabled={!isButtonEnabled}
        >
          Done{" "}
          <span className="bg-opacity-60 p-1 justify-center text-center rounded-lg text-[8px]">
            (select 4)
          </span>
          <span className="bg-red-800 bg-opacity-60 p-1 ml-1 justify-center text-center rounded-lg text-sm font-normal overflow-hidden">
            {selectedCardCount}
          </span>
        </button>
      </div>
      </div>
    </div>
  );
};

export default BTeamSide;
