import React, { useState, useEffect } from "react";
import { ref, get, set } from "firebase/database";
import { database } from "../../App";

interface Card {
  id: number;
  name: string;
  rate: number;
  result: number | null;
  databaseUserCount: number;
  databaseUserPoin: number;
  visible: boolean; // Add visible property
}

const BTeamSaha: React.FC<{ bom: boolean }> = ({ bom }) => {
  const [rightCards, setRightCards] = useState<Card[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseRef = ref(database, "teams/bTeam/bTeamSahaPlayer");
        const snapshot = await get(databaseRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const fansArray = Object.values(data) as Card[];

          // Initialize local storage for card visibility
          fansArray.forEach((card) => {
            const visibility = localStorage.getItem(`${card.id}`);
            card.visible = visibility !== null ? JSON.parse(visibility) : true;
          });

          setRightCards(fansArray);
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [bom]);

  const calculatePlayerRate = async (userRating: number, cardId: number) => {
    const updatedCards = rightCards.map((c) => {
      if (c.id === cardId) {
        const totalUserPoin = c.databaseUserPoin + userRating;
        const calculatedResult = totalUserPoin / (c.databaseUserCount + 1);

        // Toggle card visibility
        const updatedCard = {
          ...c,
          result: calculatedResult,
          databaseUserCount: c.databaseUserCount + 1,
          databaseUserPoin: totalUserPoin,
          visible: false,
        };

        // Update local storage for card visibility
        localStorage.setItem(`${cardId}`, JSON.stringify(false));

        return updatedCard;
      } else {
        return c;
      }
    });

    // Update the Firebase database with the updated cards
    const databaseRef = ref(database, "teams/bTeam/bTeamSahaPlayer");
    try {
      await set(databaseRef, updatedCards);
      setRightCards(updatedCards);
    } catch (error) {
      console.error("Error updating the database:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseRef = ref(database, "teams/bTeam/bTeamSahaPlayer");
        const snapshot = await get(databaseRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const fansArray = Object.values(data) as Card[];

          // Initialize local storage for card visibility
          fansArray.forEach((card) => {
            const visibility = localStorage.getItem(`${card.id}`);
            card.visible = visibility !== null ? JSON.parse(visibility) : true;
          });

          setRightCards(fansArray);
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [bom]);

  return (
    <div className="flex flex-col p-2 md:p-8 justify-center text-center items-center">
    <h5 className="font-bold text-lg text-indigo-800  p-1">
    Rate Today XI
    </h5>

    <div className=" border border-gray-800 flex flex-col items-center justify-start h-full md:h-[500px]  px-6 py-4 text-center overflow-hidden md:overflow-y-scroll">
        {rightCards.map((card) => (
          <div
            key={card.id}
            className="border border-black rounded-md flex justify-center items-center text-center w-[260px] p-4 m-2 bg-indigo-900 bg-opacity-90 hover:cursor-pointer"
          >
            <div
              className={
                card.visible
                  ? "visible  flex flex-col gap-1 justify-center items-center"
                  : "hidden"
              }
            >
              <div className="flex flex-row">
              <h1 className="border-blue-200 border-b p-2 text-white text-sm ">
                  {card.name}
                </h1>
              </div>
              <div>
                <div className="gap-1 flex flex-row  justify-center items-center">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((rating) => (
                    <button
                      key={rating}
                      className=" border  flex items-center text-center justify-center p-1  bg-indigo-900 bg-opacity-90 rounded-md text-white text-sm"
                      onClick={() => calculatePlayerRate(rating, card.id)}
                    >
                      {rating}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={
                !card.visible
                  ? "visible p-1 flex justify-around items-center flex-row"
                  : "hidden"
              }
            >
              <h1 className="border-blue-200 border-b p-2 text-white text-sm w-[160px] overflow-hidden">
                {card.name}  
              </h1>
              <h1 className="border-blue-200 border  rounded-md bg-white bg-opacity-90 text-black text-center relative p-1 text-bold">
                {card.result !== null && card.result !== undefined
                  ? card.result.toFixed(1)
                  : ""}
               
              </h1>
            </div>
          </div>
        ))}
    </div>
    </div>
  );
};

export default BTeamSaha;
