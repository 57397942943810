import React, { useState, useEffect } from "react";
import ATeam from "./ateam/ATeam";
import BTeam from "./bteam/BTeam";
import { ref, get } from "firebase/database";
import { database} from "../App";
import { GiClick } from "react-icons/gi";

const Main = () => {
  const [selectedTeam, setSelectedTeam] = useState(
    localStorage.getItem("selectedTeam") || ""
  );
  const [aTeam, setATeam] = useState(false);
  const [bTeam, setBTeam] = useState(false);
  const [aTeamName, setATeamName] = useState(""); // State variable to store ATeam name
  const [bTeamName, setBTeamName] = useState(""); // State variable to store BTeam name

  const aTeamClick = () => {
    setATeam(true);
    setBTeam(false);
    setSelectedTeam("aTeam");
    localStorage.setItem("selectedTeam", "aTeam");
  };

  const bTeamClick = () => {
    setBTeam(true);
    setATeam(false);
    setSelectedTeam("bTeam");
    localStorage.setItem("selectedTeam", "bTeam");
  };

  useEffect(() => {
    // Retrieve ATeam and BTeam names from the database
    const aTeamRef = ref(database, "teams/aTeam/name");
    get(aTeamRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setATeamName(snapshot.val());
        }
      })
      .catch((error) => {
        console.error("Error fetching ATeam name:", error);
      });

    const bTeamRef = ref(database, "teams/bTeam/name");
    get(bTeamRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setBTeamName(snapshot.val());
        }
      })
      .catch((error) => {
        console.error("Error fetching BTeam name:", error);
      });
  }, []);

  return (
    <div>
      <div
        className={
          selectedTeam
            ? "hidden"
            : "flex flex-row overflow-hidden items-center  gap-8 mt-20 pt-10"
        }
      >
        {/* Render team selection buttons */}
        <div className="flex flex-1 h-[200px]   bg-black bg-opacity-60 flex-col justify-around text-center items-center overflow-hidden p-2 md:p-3 gap-4"
        onClick={() => aTeamClick()}>
          <h5 className="font-bold text-sm md:text-lg text-white bg-indigo-700 bg-opacity-90 p-2 md:p-3 shadow-sm outline-none w-full h-[100px] overflow-hidden text-center justify-center items-center flex">
            {aTeamName} {/* Display ATeam name */}
          </h5>
          <button
            title="Click to select ATeam" // Add a meaningful title here
            className="flex items-center justify-center text-center font-bold text-sm md:text-lg text-white bg-indigo-700 bg-opacity-90 outline-none p-2 md:p-4 shadow-sm rounded-md cursor-pointer w-1/2 overflow-hidden"
            
          >
            <GiClick className="w-full" size={30} />
          </button>
        </div>
        <div className="flex flex-1 h-[200px]  bg-black bg-opacity-40 flex-col justify-around text-center items-center overflow-hidden p-2 md:p-3 gap-3"
        onClick={() => bTeamClick()}>
        <h5 className="font-bold text-sm md:text-lg text-white bg-indigo-700 bg-opacity-90 p-2 md:p-3 shadow-sm outline-none w-full h-[100px] overflow-hidden text-center justify-center items-center flex">
            {bTeamName} {/* Display ATeam name */}
          </h5>
          <button
            title="Click to select ATeam" // Add a meaningful title here
            className="flex items-center justify-center text-center font-bold text-sm md:text-lg text-white bg-indigo-700 bg-opacity-90 outline-none p-2 md:p-4 shadow-sm rounded-md cursor-pointer w-1/2 overflow-hidden"
            
          >
            <GiClick className="w-full" size={30} />
          </button>
        </div>
      </div>


      {/* Render ATeam or BTeam based on selectedTeam */}
      <div>{selectedTeam === "aTeam" ? <ATeam /> : null}</div>
      <div>{selectedTeam === "bTeam" ? <BTeam /> : null}</div>
    </div>
  );
};

export default Main;
