import React  from "react";



const Logo = () => (
  <div className="  top-0  center justify-center text-center items-center m-2 z-40  ">
    <div className="flex-col  bg-opacity-80  w-fit bg-black p-4  border-indigo-400 rounded-md  cursor-pointer ">
      <h1 className="flex justify-center font-bold text-4xl md:text-6xl p-2  border-indigo-800  text-indigo-700 shadow-indigo-700 shadow-sm">
        F'XI
      </h1>

      <h4 className="italic  text-xs md:text-sm  text-indigo-700  ">fans' eleven</h4>
    </div>
  </div>
);

export default Logo;
