import React from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  getAuth,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { FcGoogle } from "react-icons/fc";


import Logo from "./logo/Logo";
import Main from "../components/Main";



const Login = () => {
  const [user] = useAuthState(getAuth());
  const googleProvider = new GoogleAuthProvider();

  const handleSignOut = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      localStorage.clear();
    } catch (error) {
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      localStorage.clear();
    } catch (error) {
    }
  };

  return (
    <div className="background">
      <Logo/>
      <div className="flex flex-col flex-1 justify-start  items-center">
        {user ? (
          <div className="flex flex-col justify-center items-center text-center fixed top-2 left-2 z-20">
          
            <button
              className="justify-center items-center text-white  rounded-sm text-xs  text-center p-1  bg-red-500 bg-opacity-60 z-20"
              onClick={handleSignOut}
            >X
            </button>
       
          </div>
        ) : (
          ""
        )}
        {user ? (
          ""
        ) : (
          <div className="flex  flex-col mt-20 p-20 justify-start items-center gap-2 border border-white  rounded-md bg-white bg-opacity-30 outline-none ">
            <button
              className="flex w-[300px] p-4 rounded-md border border-yellow-400  text-indigo-700 text-xs md:text-lg bg-white bg-opacity-80 font-bold  items-center justify-center"
              onClick={handleGoogleLogin}
            >
              <FcGoogle className=" rounded-sm m-1" size={26} /> Login with Google
            </button>
          </div>
        )}

        {user ? <Main /> : ""}
      </div>
    </div>
  );
};

export default Login;
